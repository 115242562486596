<template>
  <div>
    <!-- needed to by the camera stream -->
    <video v-show="!stopped && !loading" ref="video" autoplay="true"></video>
    <div v-if="loading" class="d-flex flex-column" >
        <v-progress-circular class="mx-auto my-16" indeterminate color="primary" :size="100"/>
        <div>Loading Camera...</div>
    </div>
  </div>
</template>

<script>
import CameraPhoto, { FACING_MODES } from "jslib-html5-camera-photo";

export default {
  props: ["value"],
  data: () => {
    return {
      cameraPhoto: null,
      imgDataUri: null,
      devices: null,
      stopped: false,
      loading: true,
    };
  },
  mounted() {
    // get video and image elements from the html
    let videoElement = this.$refs.video;

    this.cameraPhoto = new CameraPhoto(videoElement);
    this.devices = navigator.mediaDevices;
      let height = window.innerHeight / 2;
      height = height < 480 ? height : 480;
      const width = window.innerWidth;

      this.cameraPhoto
        .startCamera(FACING_MODES.ENVIRONMENT, { width, height })
        .then(() => {
          console.log("Camera started !");
          this.loading = false;
        })
        .catch((error) => {
          console.error("Camera not started!", error);
          this.loading = false;
        });
  },
  beforeDestroy() {
    this.stopCamera();
  },
  methods: {
    takePhoto() {
      const config = {};
      this.imgDataUri = this.cameraPhoto.getDataUri(config);
      this.$emit("input", this.imgDataUri);
    },

    stopCamera() {
      this.cameraPhoto
        .stopCamera()
        .then(() => {
          this.stopped = true;
          console.log("Camera stoped!");
        })
        .catch((error) => {
          console.log("No camera to stop!:", error);
        });
    },
  },
};
</script>

<style></style>
